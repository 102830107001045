<template>
    <div>
        <span :class="{
            'has-text-danger': !answer
        }">{{ `${parsedAnswer}` }}</span>
    </div>
</template>
<script>
export default {
    
    props: {
        answer: {
            type: [String, Number]
        },
        builder: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        castAnswer() {
            return Number(this.answer)
        },
        parsedAnswer() {
            if(!this.answer) {
                return 'Incomplete'
            }
            return this.castAnswer + this.builder.units
        }
    }

}
</script>